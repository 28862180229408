import React, { useEffect, useState, ReactElement } from "react"
import { Campaign, ImageType, CampaignCounts } from "../types"
import Img from "gatsby-image"
import sortBy from "lodash/sortBy"
import { apiClient, reportMessage } from "../utils"
import { Link } from "gatsby"
import ContentLoader from "react-content-loader"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPaperPlane, faDollarSign } from "@fortawesome/free-solid-svg-icons"

interface Props {
  campaigns: Campaign[]
}

export default function CampaignList({ campaigns }: Props): ReactElement {
  const sortedCampaigns = sortBy(campaigns, ["priority"])
    .reverse()
    .filter(campaign => campaign.slug !== "GLSEN2023")
    .filter(campaign => !campaign.isHidden)
  const [counts, setCounts] = useState<CampaignCounts | null>(null)

  const fetchCounts = async () => {
    try {
      const { data } = await apiClient.get<CampaignCounts>("getCounts")
      setCounts(data)
    } catch (e) {
      reportMessage("fetching campaign counts failed")
    }
  }

  useEffect(() => {
    fetchCounts()
  }, [])

  return (
    <div id="campaign-list">
      <div className="campaigns">
        {sortedCampaigns.map(c => (
          <div key={c.id} className="campaign">
            <Link to={`/campaign/${c.slug}`}>
              <Img
                fluid={c.campaignImg.image?.fluid}
                alt={c.campaignName + " campaign image"}
              />
              <div className="campaign-content">
                <h3>{c.title}</h3>
                <p>
                  Proceeds donated to <b>{c.charity}</b>
                </p>
                {counts ? (
                  <div>
                    <p>
                      <i>
                        <FontAwesomeIcon icon={faPaperPlane} color="white" />
                      </i>
                      {counts[c.slug] || 0} cards sent
                    </p>
                    <p>
                      <i>
                        <FontAwesomeIcon icon={faDollarSign} color="white" />
                      </i>
                      ${counts[c.slug] || 0} donated
                    </p>
                  </div>
                ) : (
                  <ContentLoader viewBox="0 0 400 100">
                    <circle cx="20" cy="20" r="18" />
                    <rect x="50" y="10" rx="5" ry="5" width="400" height="20" />
                    <circle cx="20" cy="70" r="18" />
                    <rect x="50" y="60" rx="5" ry="5" width="320" height="20" />
                  </ContentLoader>
                )}
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

import { initialSelectionState } from "./initialState"
import { SelectionState, Rep, Campaign } from "../types"
import find from "lodash/find"

export type SelectionActionsTypes =
  | "SELECT_IMG"
  | "SELECT_REP"
  | "RESET_REPS"
  | "SELECT_CAMPAIGN"
  | "SELECT_SET_TEXT"
  | "ALL_DONE"
  | "RESET_ALL"

export type SelectionActions =
  | {
      type: "SELECT_IMG"
      postcardUrl: string
    }
  | {
      type: "SELECT_REP"
      rep: Rep
    }
  | {
      type: "RESET_REPS"
    }
  | {
      type: "SELECT_CAMPAIGN"
      campaign: Campaign
    }
  | {
      type: "SELECT_SET_TEXT"
      text: string
    }
  | {
      type: "ALL_DONE"
    }
  | {
      type: "RESET_ALL"
    }
  | {
      type: "CHECK_ALL_REPS"
      reps: Rep[]
    }

const selectionReducer = (
  state: SelectionState = initialSelectionState,
  action: SelectionActions
): SelectionState => {
  switch (action.type) {
    case "RESET_ALL":
      return initialSelectionState
    case "SELECT_CAMPAIGN":
      return { ...state, campaign: action.campaign }
    case "SELECT_IMG":
      return { ...state, postcardUrl: action.postcardUrl }
    case "SELECT_REP":
      const currentReps = state.reps
      const repName = action.rep.name
      const isSelected = find(currentReps, o => o.name === repName)
      if (isSelected) {
        const newReps: Rep[] = state.reps.filter(o => !(o.name === repName))
        return { ...state, reps: newReps }
      } else {
        return { ...state, reps: [...state.reps, action.rep] }
      }
    case "RESET_REPS":
      return { ...state, reps: [] }
    case "SELECT_SET_TEXT":
      return { ...state, text: action.text }
    case "ALL_DONE":
      return {
        ...initialSelectionState,
        campaign: state.campaign,
        allDone: true,
      }
    case "CHECK_ALL_REPS":
      return { ...state, reps: action.reps }
    default:
      throw new Error("unknown action")
  }
}

export default selectionReducer

import { UserState, UserReps, SelectionState } from "../types"

export const initialUserState: UserState = {
  address: null,
  firstName: "",
  lastName: "",
  email: "",
  reps: null,
}

export const initialSelectionState: SelectionState = {
  reps: [],
  campaign: null,
  text: "",
  postcardUrl: "",
  allDone: false,
}
